import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    //APIエラー判定用
    errorCode: null,
    errorMessageDetail: null,

    //検索条件保存用
    searchParams: {
      companyName: null,
      paymentMethod: null,
      status: null,
      containsResignedCustomer: null,
    },
    accessToken: ""
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    }
  },
  mutations: {
    setErrorCode(state, payload){
      state.errorCode = payload
    },
    setErrorMessageDetail(state, payload) {
      state.errorMessageDetail = payload
    },

    setSearchParams(state, payload){
      state.searchParams.companyName = payload.companyName
      state.searchParams.paymentMethod = payload.paymentMethod
      state.searchParams.status = payload.status
      state.searchParams.containsResignedCustomer = payload.containsResignedCustomer
    },

    setAccessToken(state, payload) {
      state.accessToken = payload
    }
  },
  
  actions: {
    /* 承認済フラグごとの顧客数の取得 */
    async getNumberOfCustomersByApprovedFlag({ commit, state }) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.get('/api/customerManagement/getNumberOfCustomersByApprovedFlag', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },
    /* 顧客検索 */
    async findCustomers({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.get('/api/customerManagement/search', {
          params: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 顧客一括承認 */
    async bulkApproval({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.put('/api/customerManagement/bulkApproval', payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 登録情報の取得 */
    async findRegistrationInfo({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.get('/api/registrationInfoCheck', {
          params: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 顧客承認 */
    async approval({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.put('/api/registrationInfoCheck/approval', null, {
          params: {company_id: payload},
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* ユーザ一覧取得 */
    async findUserInfo({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.get('/api/userList', {
          params: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 請求情報取得 */
    async findBillingInfo({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.get('/api/billingInfo', {
          params: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 利用状況の取得 */
    async findUsageInfo({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios.get('/api/inquiriesUsageStatus/searchProjects', {
          params: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 物件情報ファイル出力 */
    async outputProjectList({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios({
          url: '/api/inquiriesUsageStatus/outputProjects',
          params: payload,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },

    /* 物件出力 */
    async outputProject({ commit, state }, payload) {
      const accessToken = state.accessToken;

      const apiUrl = 
        '/api/inquiriesUsageStatus/'
        + payload.project_no
        + '/rookPlan'
      try {
        var res = await axios({
          url: apiUrl,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },
    
    /* ユーザ別利用時間ファイル出力 */
    async outputUsageTime({ commit, state }, payload) {
      try {
        const accessToken = state.accessToken;

        var res = await axios({
          url:'/api/inquiriesUsageStatus/outputUsersUsagetimes', 
          params: payload,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (res.status === 200) {
          return res
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      } 
    },
  },

  
  // modules: {
    
  // }
})
