import { msalInstance, loginRequest } from '../authConfig';
import { InteractionType } from '@azure/msal-browser';

// ルータによる遷移前のミドルウェアを登録
//   ルートのメタ属性を判定して、要認証のルートなら認証済みかを確認する。
export function registerGuard(router) {
    router.beforeEach(async (to) => {
        if (to.meta.requiresAuth) {
            const request = {
                ...loginRequest,
                redirectStartPage: to.fullPath
            }
            const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
            return shouldProceed || '/failed';
        }
    
        return true;
    });
}

// 認証済み判定処理
export async function isAuthenticated(instance, interactionType, loginRequest) {
    if( process.env.NODE_ENV == 'development' && process.env.VUE_APP_AZURE_AD_CLIENTID == null ) return true;

    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not  
    return instance.handleRedirectPromise().then(() => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            return true;
        }

        // User is not signed in and attempting to access protected route. Sign them in.
        if (interactionType === InteractionType.Popup) {
            return instance.loginPopup(loginRequest).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        } else if (interactionType === InteractionType.Redirect) {
            return instance.loginRedirect(loginRequest).then(() => {
                return true;
            }).catch(() => {
                return false;
            });
        }

        return false;
    }).catch(() => {
        return false;
    });
}