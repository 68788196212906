<template>
  <div>
    <div v-if="errorMessages.length" class="alert alert-danger fade show mb-3" role="alert">
      <span class="d-block lh-base" v-for="errorMessage in errorMessages" :key="errorMessage.index">{{ errorMessage }}</span>
    </div>
    <div v-if="warningMessages.length" class="alert alert-warning fade show mb-3" role="alert">
      <span class="d-block lh-base" v-for="warningMessage in warningMessages" :key="warningMessage.index">{{ warningMessage }}</span>
    </div>
    <div v-if="successMessages.length" class="alert my-alert-success fade show mb-3" role="alert">
      <span class="d-block lh-base" v-for="successMessage in successMessages" :key="successMessage.index">{{ successMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Messages',
  data() {
    return {
      errorMessages: [],
      warningMessages: [],
      successMessages: [],
    }
  },

  methods: {
    addErrorMsg(text) {
      this.errorMessages.push(text)
    },
    addWarningMsg(text) {
      this.warningMessages.push(text)
    },
    addSuccessMsg(text) {
      this.successMessages.push(text)
    },

    clearErrorMsg() {
      this.errorMessages.length = 0
    },
    clearWarningMsg() {
      this.warningMessages.length = 0
    },
    clearSuccessMsg() {
      this.successMessages.length = 0
    },
    clearAllMsg() {
      this.clearErrorMsg()
      this.clearWarningMsg()
      this.clearSuccessMsg()
    },

  }
  
}
</script>

<style lang="scss" scoped>
  @import "@/assets/style/style.scss";
  .my-alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

</style>