<template>
  <footer class="text-center my-font-size">© 2022 F&P All Rights Reserved.</footer>
</template>

<script>
export default {
  name: 'HeaderSection',
  computed: {
    isLoginPage: function(){
      //  console.log(this.$route.path)
      if(this.$route.path === '/') return true
      return false
    },
    isOutputPage: function(){
      if(this.$route.path === '/file-output') return true
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.my-font-size {
  font-size: 0.7rem;
}
</style>