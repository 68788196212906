// Config object to be passed to MSAL on creation
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

var authority_url = "";
var redirect_url = "";
var logout_redirect_url = "";

if( process.env.VUE_APP_AZURE_AD_CLIENTID != null ) {
    authority_url = new URL( process.env.VUE_APP_AZURE_AD_TENANTID, process.env.VUE_APP_AZURE_AD_AUTHORITY );
    redirect_url = new URL( process.env.VUE_APP_AUTH_REDIRECT_BASEURL );
    logout_redirect_url = new URL( '/signOut', process.env.VUE_APP_AUTH_REDIRECT_BASEURL );  
}

const msalConfig = {
    auth: {
      clientId: process.env.VUE_APP_AZURE_AD_CLIENTID,
      authority: authority_url.toString(),
      redirectUri: redirect_url.toString(),
      postLogoutRedirectUri: logout_redirect_url.toString()
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                // containsPii : 個人情報含有フラグ
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }  
  };

  export const msalInstance = new PublicClientApplication(msalConfig);

  // Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
  };
  