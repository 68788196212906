import { createRouter, createWebHistory } from 'vue-router'
// import UserList from '../views/UserList.vue'
// import UserSignUp from '../views/UserSignUp.vue'
// import RegistrationInfoCheck from '../views/RegistrationInfoCheck.vue'
import CustomerManagement from '../views/CustomerManagement.vue'
import InquiriesUsageStatus from '../views/InquiriesUsageStatus.vue'
import UserList from '../views/UserList.vue'
import RegistrationInfoCheck from '../views/RegistrationInfoCheck.vue'
import BillingInfo from '../views/BillingInfo'
import SignOut from '../views/SignOut'
import Failed from '../views/Failed'

import { registerGuard } from './Guard';

const routes = [
  {
    path: '/',
    name: 'CustomerManagement',
    component: CustomerManagement,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/userList',
  //   name: 'UserList',
  //   component: UserList
  // },
  // {
  //   path: '/userSignUp',
  //   name: 'UserSignUp',
  //   component: UserSignUp
  // },
  // {
  //   path: '/registrationInfoCheck',
  //   name: 'RegistrationInfoCheck',
  //   component: RegistrationInfoCheck
  // },
  {
    path: '/inquiriesUsageStatus',
    name: 'InquiriesUsageStatus',
    component: InquiriesUsageStatus,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/userList',
    name: 'UserList',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registrationInfoCheck',
    name: 'RegistrationInfoCheck',
    component: RegistrationInfoCheck,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/billingInfo',
    name: 'BillingInfo',
    component: BillingInfo,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/signOut',
    name: 'SignOut',
    component: SignOut,
  },
  {
    path: '/failed',
    name: 'Failed',
    component: Failed
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// ガード処理を追加
registerGuard(router);

export default router
