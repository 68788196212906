<template>
  <div class="my-container">
    <div class="my-side-contents">
      <menu-section></menu-section>
    </div>
    <div class="my-main-contents">
      <suspense>
        <access-token-holder />
      </suspense>
      <main-section></main-section>
    </div>
  </div>
</template>

<script>
  import MenuSection from '@/views/AdminMenuSection'
  import MainSection from '@/views/inquiriesUsageStatus/MainSection'
  
  export default {
    name: "InquiriesUsageStatus",
  
    components: {
      MenuSection,
      MainSection
    },
  }
</script>
    
<style lang="scss" scoped>
  @import "../assets/style/style.scss";
  
</style>