/* codeからtextを取得 */
function codeToText(list, code) {
  const found = list.find(elm => code === elm.code)
  return found.text
}

/* textからcodeを取得 */
function textToCode(list, text) {
  const found = list.find(elm => text === elm.text)
  return found.code
}


/* コード */
const authorityList = [
  { code: 0, text: '一般'},
  { code: 1, text: '管理者'}
]

const paymentMethodList = [
  { code: 1, text: 'クレジットカード' },
  { code: 2, text: '請求書払い' }
]

const processingStatusList = [
  { code: 0, text: '未確定'},
  { code: 1, text: '完了'},
  { code: 9, text: '決済失敗'}
]

const resignedFlagList = [
  { code: 0, text: '会員' },
  { code: 1, text: '翌月退会' },
  { code: 2, text: '退会済'}
]

const statusList = [
  { code: 0, text: '未承認' },
  { code: 1, text: '承認済' },
  { code: 2, text: '変更承認待ち' },
  { code: 3, text: '否認' }
]

const supervisorFlagList = [
  { code: 0, text: '非担当者' },
  { code: 1, text: '担当者'}
]

export {
  authorityList,
  paymentMethodList,
  processingStatusList,
  resignedFlagList,
  statusList,
  supervisorFlagList,

  codeToText,
  textToCode,
}