<template>
  <div class="my-container">
    <div class="my-side-contents">
      <menu-section></menu-section>
    </div>
    <div class="my-main-contents">
      <suspense>
        <access-token-holder />
      </suspense>
      <main-section @backing="setBacking"></main-section>
    </div>
  </div>
</template>

<script>
  import MenuSection from '@/views/AdminMenuSection'
  import MainSection from '@/views/billingInfo/MainSection'
  
  export default {
    name: "BillingInfo",
  
    components: {
      MenuSection,
      MainSection
    },

    /* 遷移前のパラメータ設定 */
    beforeRouteLeave(to, from, next) {
      if (this.backing) {
        to.params.isBack = true
        to.params.companyId = this.$route.params.companyId
      }
      this.backing = false
      next()
    },

    data() {
      return {
        backing: false
      }
    },

    methods: {
      setBacking() {
        this.backing = true
      },
    }
  }
</script>
  
<style lang="scss" scoped>
  @import "../assets/style/style.scss";
  
</style>