<template>
  <div id="billing-info-main" class="my-overflow-y mx-sm-3 mx-5 mt-4">
    <h4 class="mb-4">請求情報</h4>
    <messages ref="messages"></messages>
    <div class="container ms-0">
      <div class="row mb-4" style="max-width:1020px;">
        <span class="d-flex align-items-center col-sm-2 border my-bg-color fw-bold">会社名</span>
        <span class="col-sm-9 col-xl-10 p-2 border">{{ customerInfo.companyName }}</span>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered table-sm small table-hover mt-1 text-center">
        <thead class="table-light align-middle">
          <tr>
            <th v-for="field in billingInfoListHeader" :key="field.key">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(charge, index) in customerInfo.billingInfoList" :key="index">
            <td>{{ charge.targetMonth }}</td>
            <td>{{ charge.chargedLicenseNum }}</td>
            <td class="text-end">￥{{ charge.chargeExcludedTax }}</td>
            <td class="text-end">￥{{ charge.chargeIncludedTax }}</td>
            <td>{{ charge.paymentMethod }}</td>
            <td>{{ charge.paymentStatus }}</td>
            <td class="text-start">{{ charge.message }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-end mt-5 p-2">
      <button class="btn btn-primary btn-md me-4" @click="$_back">戻る</button>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="billing-info-main" />
  </div>
</template>

<script>
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import Messages from '@/views/common/Messages'
  import { paymentMethodList, processingStatusList, codeToText, textToCode } from '@/assets/js/code.js'
  import { cmnInsertDelimiter, cmnChangeFormatDate } from '@/assets/js/common.js'

  export default {

    name: 'MainSection',

    components: {
      ModalBlockMessage,
      Messages
    },

    data() {
      return {
        customerInfo: {
          copanyId: this.$route.params.companyId,
          companyName: null,
          billingInfoList: [], // property: targetMonth, chargedLicenseNum, chargeIncludedTax, chargeExcludedTax, paymentMethod, paymentStatus, message
        },
        billingInfoListHeader: [
          { key: 'targetMonth', label: '年月' },
          { key: 'chargedLicenseNum', label: 'ライセンス数' },
          { key: 'chargeExcludingTax', label: '合計額（税抜き）' },
          { key: 'chargeIncludedTax', label: '合計額（税込み）' },
          { key: 'paymentMethod', label: '決済手段' },
          { key: 'paymentStatus', label: '決済ステータス' },
          { key: 'message', label: '備考' }
        ],
        billingInfoItem: [],
      }
    },

    mounted() {
      this.$_viewBillingInfoList()
    },

    methods: {
      /* 請求情報一覧の取得 */
      $_viewBillingInfoList(){
        this.$refs.modalBlockMessage.init()
        let payload = {
          company_id: this.$route.params.companyId
        }
        this.$store.dispatch('findBillingInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            
            this.customerInfo.companyName = res.company.companyName
            this.$_setBillingInfoList(res.charges)
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
      },

      /* 取得した情報のセット */
      $_setBillingInfoList(charges) {
        for(let charge of charges) {
          let billingInfo = {
            targetMonth: cmnChangeFormatDate(charge.targetMonth, 'YYYYMM', 'YYYY/MM'),
            chargedLicenseNum: charge.numberOfLicensesCharged,
            chargeExcludedTax: cmnInsertDelimiter(charge.chargeExcludedTax.toString(), ',', 3),
            chargeIncludedTax: cmnInsertDelimiter(charge.chargeIncludedTax.toString(), ',', 3),
            paymentMethod: codeToText(paymentMethodList, charge.paymentMethod),
            paymentStatus: null,
            message: charge.creditMessage
          }
          if(charge.paymentMethod === textToCode(paymentMethodList, '請求書払い') &&
              charge.processingStatus == textToCode(processingStatusList, '完了')) {
            billingInfo.paymentStatus = '-'
          } else {
            billingInfo.paymentStatus = codeToText(processingStatusList, charge.processingStatus)
          }
          this.customerInfo.billingInfoList.push(billingInfo)

        }
      },

      /* 戻る */
      $_back() {
        this.$emit('backing')
        this.$router.back()
      },

    }
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.my-bg-color {
  background: rgba(128, 128, 128, 0.3);
}
.table>:not(:first-child) {
  border-top: 1;
  border-top-color: gainsboro;
}
.table th:nth-child(1), .table td:nth-child(1) {
  width: 7%;
}
.table th:nth-child(2), .table td:nth-child(2) {
  width: 7%;
  min-width: 50px;
}
.table th:nth-child(3), .table td:nth-child(3),
.table th:nth-child(4), .table td:nth-child(4) {
  width: 13%;
  min-width: 120px;
}
.table th:nth-child(5), .table td:nth-child(5),
.table th:nth-child(6), .table td:nth-child(6) {
  width: 10%;
  min-width: 80px;
}
.table th:nth-child(7), .table td:nth-child(7) {
  width: calc(100% - 60%);
}
</style>