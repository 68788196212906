<template>
  <div class="my-container">
    <div class="my-side-contents">
      <menu-section ref="menuSection"></menu-section>
    </div>
    <div class="my-main-contents">
      <suspense>
        <access-token-holder />
      </suspense>
      <main-section @reloadMenuSection="$_reloadMenuSection"></main-section>
    </div>
  </div>
</template>

<script>
  import { fncCheckAppVersionMatching } from '@/assets/js/function.js'
  import MenuSection from '@/views/AdminMenuSection'
  import MainSection from '@/views/customerManagement/MainSection'
  import AccessTokenHolder from './common/AccessTokenHolder';

  export default {
    name: "CustomerManagement",
  
    components: {
      MenuSection,
      MainSection,
      AccessTokenHolder,
    },

    beforeCreate() {
      
    },
  mounted() {
    this.$_init()
  },

    methods: {
      /* 初期化 */
      $_init() {
        fncCheckAppVersionMatching()
          .then(res => {
            if(res) {
              console.log('アプリは最新です。')
            } else {
              console.log('アプリの変更を検知しました。リロードします。')
              const href = window.location.href
              window.location.href = href

            }

          })
      },

      $_reloadMenuSection() {
        this.$refs.menuSection.init()
      }
    },

  }
</script>
    
<style lang="scss" scoped>
  @import "../assets/style/style.scss";

</style>