<template>
  <div name="modal-block-message" class="modal" tabindex="-1" data-bs-keyboard="false" aria-labelledby="modal-block-message" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row my-loader"></div>
            <div class="row">
              <span class="d-block lh-base text-center fs-4" v-for="(message, index) in blockMessages" :key="index">{{ message }}</span>
            </div>
            <div class="mt-2 mb-auto progress" v-if="displayProgress">
              <div class="progress-bar" role="progressbar" :style="{width: percent + '%'}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                {{ percent }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { cmnIsBlank } from '@/assets/js/common'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalBlockMessage',
  props: {
    propBlockMessages: {
      type: Array,
      default: () => ['処理中...']
    },
    parentId: {
      type: String,
      default: ''
    },
    propPercent: {
      type: Number,
      default: 0
    },
    displayProgress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myModal: null,
      blockMessages: [],
      percent: 0
    }
  },
  watch: {
    propBlockMessages: {
      handler: function(newVal) {
        this.blockMessages = newVal
      },
      deep : true,
    },
    propPercent: function(newVal) {
      this.percent = newVal
    }
  },
  methods: {
    /* 初期化 */
    init() {
      this.parcent = 0
      this.blockMessages = this.propBlockMessages
      if (cmnIsBlank(this.parentId))
        this.myModal = new Modal(document.getElementsByName('modal-block-message')[0], {})
      else
        this.myModal = new Modal(document.getElementById(this.parentId).querySelector('div[name="modal-block-message"]'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* close */
    close() {
      this.myModal.hide()
      fncAdjustModalLayer()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-loader {
  margin: 50px auto;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load 1.1s infinite ease;
  animation: load 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #0079c3, 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.5), -1.8em -1.8em 0 0em rgba(0,121,195, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.7), 1.8em -1.8em 0 0em #0079c3, 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.5), 1.8em -1.8em 0 0em rgba(0,121,195, 0.7), 2.5em 0em 0 0em #0079c3, 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.5), 2.5em 0em 0 0em rgba(0,121,195, 0.7), 1.75em 1.75em 0 0em #0079c3, 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.5), 1.75em 1.75em 0 0em rgba(0,121,195, 0.7), 0em 2.5em 0 0em #0079c3, -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.5), 0em 2.5em 0 0em rgba(0,121,195, 0.7), -1.8em 1.8em 0 0em #0079c3, -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.5), -1.8em 1.8em 0 0em rgba(0,121,195, 0.7), -2.6em 0em 0 0em #0079c3, -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.5), -2.6em 0em 0 0em rgba(0,121,195, 0.7), -1.8em -1.8em 0 0em #0079c3;
  }
}
@keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #0079c3, 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.5), -1.8em -1.8em 0 0em rgba(0,121,195, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.7), 1.8em -1.8em 0 0em #0079c3, 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.5), 1.8em -1.8em 0 0em rgba(0,121,195, 0.7), 2.5em 0em 0 0em #0079c3, 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.5), 2.5em 0em 0 0em rgba(0,121,195, 0.7), 1.75em 1.75em 0 0em #0079c3, 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.5), 1.75em 1.75em 0 0em rgba(0,121,195, 0.7), 0em 2.5em 0 0em #0079c3, -1.8em 1.8em 0 0em rgba(0,121,195, 0.2), -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.5), 0em 2.5em 0 0em rgba(0,121,195, 0.7), -1.8em 1.8em 0 0em #0079c3, -2.6em 0em 0 0em rgba(0,121,195, 0.2), -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.5), -1.8em 1.8em 0 0em rgba(0,121,195, 0.7), -2.6em 0em 0 0em #0079c3, -1.8em -1.8em 0 0em rgba(0,121,195, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,121,195, 0.2), 1.8em -1.8em 0 0em rgba(0,121,195, 0.2), 2.5em 0em 0 0em rgba(0,121,195, 0.2), 1.75em 1.75em 0 0em rgba(0,121,195, 0.2), 0em 2.5em 0 0em rgba(0,121,195, 0.2), -1.8em 1.8em 0 0em rgba(0,121,195, 0.5), -2.6em 0em 0 0em rgba(0,121,195, 0.7), -1.8em -1.8em 0 0em #0079c3;
  }
}

</style>