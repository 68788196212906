/* 定数 */
const RESULT_CODE = {
  OK: 1,
  NG: 0
};
const FLAG = {
  ON: 1,
  OFF: 0
};

const REGEXP = {
  FILENAME: /(?=filename\*=UTF-8).*/
}

export {
  RESULT_CODE, FLAG, REGEXP
}