<template>
  <div class="text-center m-3">
    <img src="../../assets/images/logo.png" style="width: 130px;">
  </div>
</template>

<script>
export default {
  name: 'HeaderSection'
}
</script>

<style lang="scss" scoped>
</style>