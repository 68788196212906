import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { msalPlugin } from './plugins/msalPlugin';
import { msalInstance } from './authConfig';
import { EventType } from '@azure/msal-browser';
import { CustomNavigationClient } from './router/NavigationClient';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '../scss/layout.scss'

// 認証付きナビゲーション使用
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(msalPlugin, msalInstance);
// ログインページへリダイレクトになった場合に非同期準備処理があるので
// 完了を待ち合わせてからアプリケーションをマウントする
router.isReady().then( () => {
    app.mount('#app');
});  
