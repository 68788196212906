<script setup>
    import { useStore } from 'vuex'
    import { useMsal } from '../../composition-api/useMsal';

    const { instance } = useMsal();
    const store = useStore()

    const accessTokenRequest = {
        account: instance.getActiveAccount(),
        scopes: [
            process.env.VUE_APP_AZURE_AD_API_SCOPE
        ]  
    };
    
    const atr = await instance.acquireTokenSilent(accessTokenRequest);
    const accessToken = atr.accessToken;
    store.commit( 'setAccessToken', accessToken );
</script>
