<template>
  <div>
      ログインエラー
  </div>
</template>

<script>
</script>
    
<style lang="scss" scoped>
  @import "../assets/style/style.scss";
  
</style>