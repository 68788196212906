import store from '@/store/index.js';

//-----------------------------------------------
//	モーダルレイヤ調整
//-----------------------------------------------
function fncAdjustModalLayer() {
  try {
    //submodal_object.modal('show');
    // オーバーレイ要素が</body>直前に追加されるのを待つためsetTimeoutを使う
    setTimeout(function () { 
      // Bootstrapモーダル要素z-indexの初期値
      const default_zindex_overlay = 1040;
      const default_zindex_modal = 1060;

      // イベント編集に関わるモーダル要素を取得
      const overlay_elements = document.getElementsByClassName('modal-backdrop');
      const modal_elements = document.getElementsByClassName('modal');

      if (overlay_elements.length >= 2) { // 多層表示時に限り各モーダル要素のCSS:z-index値を調整
        for (let i = 0, len = overlay_elements.length; i < len; i++) {
          let add = 20*i;
          // 要素の増減有無に応じたz-indexの設定をする
          if (i < overlay_elements.length)
            overlay_elements[i].style.zIndex = default_zindex_overlay + add;
      }
      let ii = 0;
      for (let i = 0, len = modal_elements.length; i < len; i++) {
          // 要素の増減有無に応じたz-indexの設定をする
          if (modal_elements[i].classList.contains('show')){
            let add = 20*ii;
            modal_elements[i].style.zIndex = default_zindex_modal + add;
            ii++;
          }
        }
      }
    }, 200);

  } catch (e) {
    alert(e);
  }
}

//-----------------------------------------------
//	プロジェクト名作成
//  address: 住所
//-----------------------------------------------
function fncMakeProjectName(address) {
  const projectName = store.state.projectName
  return (projectName.prefix !== null ? projectName.prefix : '') + address + (projectName.suffix !== null ? projectName.suffix : '')
}

//-----------------------------------------------
//	アプリのバージョンチェック（リリース時のキャッシュ対策）
//-----------------------------------------------
async function fncCheckAppVersionMatching() {
  const localHtml = document.documentElement

  const url = location.href
  const serverHtml = await getServerHtml(url)

  const result =  judgeMatching(localHtml, serverHtml)
  return result

  // サーバ上から最新のHTMLファイルを取得
  async function getServerHtml(url) {
    return fetch(url, {
      method: 'GET',
      headers: {
        'cache-control' : 'no-cache'
      }
    }).then(res =>{
      return res.text()
    }).then(str => {
      const html = document.createElement('div')
      html.innerHTML = str
      return html
    })

  }

  // ローカルとサーバのjsファイルのファイル名を比較
  // ソースが異なる場合、ビルド時に付与されるハッシュ値が異なる
  function judgeMatching(localHtml, serverHtml) {
    let match = true
    const localScripts = Array.from(localHtml.querySelectorAll('script'))
    const serverScripts = Array.from(serverHtml.querySelectorAll('script'))

    if(localScripts.length !== serverScripts.length){
      match = false
      return match
    }

    localScripts.forEach((localScript, index) => {
      const localSrc = localScript.getAttribute('src')
      const serverSrc = serverScripts[index].getAttribute('src')
      if(localSrc !== serverSrc) {
        match =  false
      }
    })
    return match
  }
}

export { 
    fncAdjustModalLayer,
    fncMakeProjectName,
    fncCheckAppVersionMatching
}

