<template>
  <div class="no-scrollbar position-fixed" style="height: 100vh;">
    <div class="d-flex flex-column h-50">
      <header-section></header-section>
      <div class="px-4 py-3">
        <nav class="nav nav-fill flex-column">
          <button class="btn btn-outline-primary fw-bold" v-bind:class="[this.$route.path === '/' ? 'active' : '']" @click="moveToCustomerManagement">顧客管理</button>
          <button class="btn btn-outline-primary fw-bold mt-3" v-bind:class="[this.$route.path === '/inquiriesUsageStatus' ? 'active' : '']" @click="moveToInquiriesUsageStatus">利用状況照会</button>
          <button class="btn btn-outline-primary fw-bold mt-3" @click="openManual">マニュアル</button>
          <button class="btn btn-outline-primary fw-bold mt-3" @click="moveToSignOut">サインアウト</button>
        </nav>
      </div>
    </div>
    <div class="d-flex flex-column h-50 ">
      <div class="information-area m-0 p-2 w-100">
        <h4 class="information-header text-center text-primary border-primary border-bottom">
          Information</h4>
        <div class="information-articles" v-if="isLoaded">
          <article class="information-article mt-1 px-0 py-2 border-bottom" v-if="showApprovalAlert">
            <h6 class="article-title d-flex align-items-center">
              <img class="mx-1" src="@\assets\images\exclamation-triangle-fill.svg">承認してください</h6>
            <div class="article-body small my-1 px-3">
              <p>「未承認」が<span class="fw-bold mx-1"> {{ approvalAlertInfo.notApproved }} </span>件、
                  「変更承認待ち」が<span class="fw-bold mx-1"> {{ approvalAlertInfo.pendingChangeApproval }} </span>件あります。
                  <br>顧客管理画面より承認してください。
              </p>
            </div>
          </article>
          <article class="information-article mt-1 px-0 py-2 border-bottom" v-else>
            <div class="article-body small my-1 px-3">
              <p>現在、表示するお知らせはありません。
              </p>
            </div>
          </article>
        </div>
        <div class="information-articles" v-if="!isLoaded">
          <article class="information-article mt-1 px-0 py-2 border-bottom">
            <div class="article-body small my-1 px-3">
              <p>読み込んでいます...</p>
            </div>
          </article>
        </div>
      </div>
      <div class="mt-auto py-3">
        <footer-section></footer-section>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'

import { useMsal } from '../composition-api/useMsal';

export default {
  name: 'AdminMenuSection',
  components: {
    HeaderSection,
    FooterSection,
  },

  setup() {
    const { instance } = useMsal();
    
    const logout = () => {
      const currentAccount = instance.getActiveAccount();
      if( currentAccount != null ) {
        const logoutHint = currentAccount.homeAccountId;
        instance.logoutRedirect({
          logoutHint: logoutHint
        });
      }

      // 認証が実施されていた場合はリダイレクトされているので、ここには届かない
      return true;
    }

    return {
      instance,
      logout
    }
  },

  data() {
    return {
      isLoaded: false,
      counter: 0,
      approvalAlertInfo: {
        notApproved: 0,
        pendingChangeApproval: 0
      },
    }
  },

  computed: {
    showApprovalAlert() {
      return this.approvalAlertInfo.notApproved || this.approvalAlertInfo.pendingChangeApproval
    }
  },

  mounted() {

    this.init()
  },
  
  methods: {
    /* 初期化 */
    async init() {
      this.isLoaded = false

      if(!this.$store.state.accessToken && this.counter < 10) {
        setTimeout(this.init, 500)
        this.counter++
      } else {
        this.$_getApprovalAlertInfo()
        this.counter = 0
      }
    },

    /* 顧客管理 */
    moveToCustomerManagement() {
      this.$router.push({ path: '/' })
    },

    /* 利用状況照会 */
    moveToInquiriesUsageStatus() {
      this.$router.push({ path: 'inquiriesUsageStatus' })
    },
    
    /* マニュアル */
    openManual(){
      window.open("./customer-mgmt_manual.pdf",
      "_blank")
    },

    /* サインアウト */
    moveToSignOut() {
      if( this.logout() ) {
        this.$router.push({ path: '/signOut'})
      }
    },

    /* 承認アラート情報の取得 */
    $_getApprovalAlertInfo() {
      this.$store.dispatch('getNumberOfCustomersByApprovedFlag')
        .then((res) => {
          this.$_setApprovalAlertInfo(res)
          this.isLoaded = true
          })
					.catch((e) => {
						console.log(e)
					}) 
    },

    /* 承認アラート情報のセット */
    $_setApprovalAlertInfo(approvalAlertInfo) {
      this.approvalAlertInfo.notApproved = approvalAlertInfo.notApproved
      this.approvalAlertInfo.pendingChangeApproval = approvalAlertInfo.pendingChangeApproval
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-scrollbar {
    overflow-y: hidden;
    width: 350px;
  }
</style>