<template>
    <div class="my-container">
      <div class="my-side-contents">
        <menu-section ref="menuSection"></menu-section>
      </div>
      <div class="my-main-contents">
        <suspense>
          <access-token-holder />
        </suspense>
        <main-section :show-admin="true" @backing="setBacking" @reloadMenuSection="$_reloadMenuSection"></main-section>
      </div>
    </div>
</template>

<script>
  import MenuSection from '@/views/AdminMenuSection'
  import MainSection from '@/views/registrationInfoCheck/MainSection'
  
  export default {
    name: "RegistrationInfoCheck",

    components: {
      MenuSection,
      MainSection
    },

    /* 遷移前のパラメータ設定 */
    beforeRouteLeave(to, from, next) {
      if (this.backing) to.params.isBack = true
      this.backing = false
      next()
    },

    data() {
      return {
        backing: false
      }
    },

    methods: {
      setBacking() {
        this.backing = true
      },

      $_reloadMenuSection() {
        this.$refs.menuSection.init()
      }
    }
    
  }
</script>
  
<style lang="scss" scoped>
      @import "../assets/style/style.scss";
    
</style>