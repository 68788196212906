<template>
  <div id="registration-info-check-main" class="my-overflow-y mx-sm-3 mx-5 mt-4 no-scrollbar">
    <h4 class="pb-4 border-bottom">登録情報確認</h4>
    <div class="container" style="max-width:820px; word-break:break-all;">
      <messages ref="messages"></messages>

      <div class="d-flex justify-content-end">
        <span>登録日: {{  registrationInfo.registDate }}</span>
      </div>

      <div class="row d-flex align-items-stretch border my-2">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">会社名</div>
        <div class="col-9 py-2">{{  registrationInfo.companyName }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">部署名</div>
        <div class="col-9 py-2">{{  registrationInfo.departmentName }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">担当者名</div>
        <div class="col-9 py-2">{{  registrationInfo.supervisorName }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">メールアドレス</div>
        <div class="col-9 py-2">{{  registrationInfo.supervisorEmail }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">電話番号</div>
        <div class="col-9 py-2">{{  registrationInfo.telephoneNumber }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">ライセンス数</div>
        <div class="col-9 py-2">{{  registrationInfo.licenseNum }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">決済手段</div>
        <div class="col-9 py-2">{{  registrationInfo.paymentMethod }}</div>
      </div>
      
      <div class="row d-flex align-items-stretch border my-2 " if="registrationInfo.resignedFlag === 0 || ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">クレジットカード会員ID</div>
        <div class="col-9 py-2">{{  registrationInfo.creditCardMembershipId }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">郵便番号</div>
        <div class="col-9 py-2">{{  registrationInfo.billingPostalCode }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">住所</div>
        <div class="col-9 py-2">{{  registrationInfo.billingAddress }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">その他特記事項など</div>
        <div class="col-9 py-2">{{ registrationInfo.specialNote }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">ステータス</div>
        <div class="col-9 py-2">{{  registrationInfo.status }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">会員状況</div>
        <div class="col-9 py-2">{{  registrationInfo.resignedFlag }}</div>
      </div>

      <div class="row d-flex align-items-stretch border my-2 ">
        <div class="col-3 py-2 my-bg-color fw-bold d-flex align-items-center">ユーザ数</div>
        <button class="btn btn-link btn-sm text-start col-9 py-2" type="link"
						@click="$_moveToUserList(registrationInfo.companyId)">{{ registrationInfo.userCount }}
        </button>
      </div>
      
      <div class="my-4 d-flex justify-content-end">
        <div>
          <button class="btn btn-primary" @click="$_approveBtn" v-show="showApproveBtn">承認</button>
          <button class="btn btn-primary ms-4" @click="$_back">戻る</button>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="registration-info-check-main" />
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="registration-info-check-main" @return-confirm-result="returnConfirmResult" />
  </div>
</template>

<script>
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import ModalConfirm from '@/views/common/ModalConfirm'
  import Messages from '@/views/common/Messages'
  import { paymentMethodList, statusList, resignedFlagList, codeToText, textToCode } from '@/assets/js/code.js'
  import { cmnDevidePostalCode, cmnChangeFormatDate } from '@/assets/js/common.js'
  import { RESULT_CODE } from '@/assets/js/const'

  export default {
    name: 'MainSection',

		components: {
      ModalBlockMessage,
      ModalConfirm,
			Messages
		},

    data() {
      return {
        registrationInfo: {
          companyId: null,
          registrationDate: null,
          companyName: null,
          departmentName: null,
          telephoneNumber: null,
          supervisorName: null,
          supervisorEmail: null,
          licenseNum: null, 
          paymentMethod: null,
          creditRegist: false,
          billingPostalCode: null,
          billingAddress: null,
          specialNote: null,
          statusCode: null,
          status: null,
          resignedFlagCode: null,
          resignedFlag: null,
          userCount: null,
        },
        confirmMessages: [],

      }
    },

    computed: {
      showApproveBtn() {
        return ((this.registrationInfo.statusCode === textToCode(statusList, '未承認') || this.registrationInfo.statusCode === textToCode(statusList, '変更承認待ち')) &&
          (this.registrationInfo.resignedFlagCode !== textToCode(resignedFlagList, '退会済'))
        )
      }
    },

    mounted() {
        this.$_getRegistrationInfo()
    },

    methods: {
      /* 顧客情報の取得 */
      $_getRegistrationInfo() {
        this.$refs.modalBlockMessage.init()
        let payload = {
          company_id: this.$route.params.companyId
        }
        this.$store.dispatch('findRegistrationInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)

            const customerData = res.customerDataList[0]
            if(customerData === null || customerData === undefined) 
              return this.$refs.messages.addErrorMsg('顧客情報の読み込みに失敗しました。')

              this.$_setRegistrationInfo(customerData)
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
      },
      
      /* 取得した情報を画面にセット */
      $_setRegistrationInfo(customerData) {
        const regInfo = this.registrationInfo
        regInfo.companyId = customerData.company.companyId
        regInfo.registDate = cmnChangeFormatDate(new Date(Date.parse(customerData.company.registDate)), 'date', 'YYYY/MM/DD')
        regInfo.companyName = customerData.company.companyName
        regInfo.departmentName = customerData.company.departmentName
        regInfo.telephoneNumber = customerData.company.telephoneNumber
        regInfo.supervisorName = customerData.supervisorName
        regInfo.supervisorEmail = customerData.supervisorEmail
        regInfo.licenseNum = customerData.company.numberOfLicenses
        regInfo.paymentMethod = codeToText(paymentMethodList, customerData.charge.paymentMethod)
        regInfo.creditRegist = (customerData.charge.paymentMethod === textToCode(paymentMethodList, 'クレジットカード'))
        regInfo.creditCardMembershipId = customerData.charge.creditCardMembershipId


        regInfo.billingPostalCode =
          cmnDevidePostalCode(customerData.charge.billingPostalCode)[0] +
          '-' +
          cmnDevidePostalCode(customerData.charge.billingPostalCode)[1]
        regInfo.billingAddress = customerData.charge.billingAddress

        regInfo.specialNote = customerData.company.specialNote
        regInfo.statusCode = customerData.company.approvedFlag
        regInfo.status = codeToText(statusList, customerData.company.approvedFlag)
        regInfo.resignedFlagCode = customerData.company.resignedFlag
        regInfo.resignedFlag = codeToText(resignedFlagList, customerData.company.resignedFlag)
        regInfo.userCount = customerData.userCount
      },

      
      /* 承認ボタン */
			$_approveBtn() {
				this.$refs.messages.clearAllMsg()
        this.confirmMessages = []
        this.confirmMessages.push('承認します。', 'よろしいですか？')
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()

			},

      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.OK) this.$_approveCustomer()
      },

      /* 顧客登録の承認 */
      $_approveCustomer() {
        this.$refs.modalBlockMessage.init()
        let payload = this.registrationInfo.companyId 
        this.$store.dispatch('approval', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            window.scrollTo(0, 0)
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
              this.$refs.messages.addSuccessMsg(`「${this.registrationInfo.companyName}」を承認しました。`)
              this.$_getRegistrationInfo()
              this.$emit('reloadMenuSection')
            })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
      },

      /* ユーザ一覧に遷移 */
      $_moveToUserList(companyId) {
				this.$router.push({ path: '/userList', name: 'UserList', params: { companyId: companyId } })
			},

      /* 戻る */
      $_back() {
        this.$emit('backing')
        this.$router.back()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .no-scrollbar {
    overflow-x: hidden;
  }
  .my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  }
  .my-bg-color {
    background: rgba(128, 128, 128, 0.3);
  }
</style>