<template>
  <div id="inquiriesUsageStatus" class="my-overflow-y mx-sm-3 mx-5 mt-4 no-scrollbar">
    <h4 class="mb-4">利用状況照会</h4>
    <div class="container">

      <!-- 照会対象選択エリア -->
      <div class="row align-items-center pt-1 border-top">
        <div class="col-sm-3 py-1 my-bg-color fw-bold">対象</div>
        <div class="col-sm-9 d-flex">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="targetRadio" id="objInfo" v-model="target"
                :value="targetProject" @click="$_clearAllMsg()">
            <label class="form-check-label" for="objInfo">物件情報</label>
          </div>
          <div class="form-check ms-5">
            <input class="form-check-input" type="radio" name="targetRadio" id="usageTime" v-model="target"
                :value="targetUsagetime" @click="$_clearAllMsg(); $_tableReset()">
            <label class="form-check-label" for="usageTime">利用時間</label>
          </div>
        </div>
      </div>

      <!-- 物件情報照会エリア -->
      <div v-if="target === targetProject">
        <div class="row align-items-center mt-1 pt-1 border-top">
          <div id="companyName0" class="col-sm-3 py-1 my-bg-color fw-bold">会社名</div>
          <div class="col-sm-9"><input class="p-1 m-0 form-control form-control-sm" type="text"
              v-model="companyName" /></div>
        </div>
        <div>
          <div class="row align-items-center mt-1 pt-1 border-top">
            <div class="col-sm-3 py-1 my-bg-color fw-bold">住所</div>
            <div class="col-sm-9"><input class="p-1 m-0 form-control form-control-sm" type="text"
              v-model="projectAddress" /></div>
          </div>
          <div class="row align-items-center my-1 py-1 border-top border-bottom">
            <div class="col-sm-3 py-1 my-bg-color fw-bold">利用期間</div>
            <div class="col-sm-9 d-flex">
              <div class="col-3 p-0"><input class="p-1 m-0 form-control form-control-sm" type="date"
                  v-model="usagePeriodFrom" /></div>
              <span class="mx-1">～</span>
              <div class="col-3 p-0"><input class="p-1 m-0 form-control form-control-sm" type="date"
                  v-model="usagePeriodTo" /></div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="my-2 col-sm-12 text-center"><button class="btn btn-primary"
              @click="$_viewBtn()">表示</button></div>
        </div>
      </div>
      <messages ref="messagesForProject"></messages>

      <!-- 物件情報一覧表示エリア -->
      <div class="col-12" v-if="target === targetProject && showTable">
        <table class="table table-bordered table-sm small my-5 table-hover">
          <thead class="table-light align-middle">
            <tr>
              <th v-for="field in userAdminList" :key="field.key">{{ field.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usageStatus, index) in usageStatusList" :key="index">
              <td id="td1">{{ usageStatus.updateDate }}</td>
              <td id="td2">{{ usageStatus.resignedFlag }}</td>
              <td id="td3">{{ usageStatus.companyName }}</td>
              <td id="td4">{{ usageStatus.email }}</td>
              <td id="td5">{{ usageStatus.projectId }}</td>
              <td id="td6">{{ usageStatus.projectName }}</td>
              <td id="td7">{{ usageStatus.address }}</td>
              <td id="td8"><button class="btn btn-primary btn-sm" type="button" @click="$_outputProject(usageStatus.projectNo)">出力</button></td>
            </tr>
          </tbody>
        </table>
        <div class="text-end p-2">
          <button class="btn btn-primary btn-sm" @click="$_outputProjectList()">一覧出力</button>
        </div>
      </div>

      <!-- 利用時間照会エリア -->
      <div v-if="target === targetUsagetime">
        <div class="row align-items-center my-1 py-1 border-top border-bottom">
          <div id="companyName1" class="col-sm-3 py-1 my-bg-color fw-bold">会社名</div>
          <div class="col-sm-9"><input class="p-1 m-0 form-control form-control-sm" type="text"
              v-model="companyName" /></div>
        </div>
        <div class="row align-items-center">
          <div class="my-2 col-sm-12 text-center">
            <button class="btn btn-primary" @click="$_outputUsageTime()">出力</button>
          </div>
        </div>
      </div>
      <messages ref="messagesForUsageTime"></messages>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="inquiriesUsageStatus" />
  </div>
</template>

<script>
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import Messages from '@/views/common/Messages'
  import { REGEXP } from '@/assets/js/const.js'
  import { resignedFlagList, codeToText } from '@/assets/js/code.js'
  import { cmnCheckDatePeriod, cmnChangeFormatDate } from '@/assets/js/common.js'

  const TARGET_PROJECT = 0
  const TARGET_USAGETIME = 1

  export default {
    name: 'MainSection',

    components: {
      ModalBlockMessage,
      Messages
    },

    data() {
      return {
        userAdminList: [
          { key: 'updateDate', label: '更新日' },
          { key: 'resignedFlag', label: '会員状況' },
          { key: 'companyName', label: '会社名' },
          { key: 'userEmail', label: 'ユーザメールアドレス' },
          { key: 'projectId', label: '物件管理ID' },
          { key: 'projectName', label: 'プロジェクト名' },
          { key: 'address', label: '住所' },
          { key: 'detail', label: null }
        ],
        usageStatusList: [],
        companyName: null,
        projectAddress: null,
        usagePeriodFrom: '',
        usagePeriodTo: '',
        searchConditionSaved: {
          companyName: null,
          projectAddress: null,
          usagePeriodFrom: '',
          usagePeriodTo: '',
        },
        target: TARGET_PROJECT,
        showTable: null,
      }
    },

    computed: {
      targetProject: function() {
        return TARGET_PROJECT
      },
      targetUsagetime: function() {
        return TARGET_USAGETIME
      }
    },


    methods: {
      /* 表示ボタン */
      $_viewBtn() {
        this.$refs.messagesForProject.clearAllMsg()
        this.$_viewList()
      },

      /* 検索と一覧表示 */
      $_viewList() {
        if(this.usagePeriodFrom &&
          this.usagePeriodTo &&
          !cmnCheckDatePeriod(this.usagePeriodFrom , this.usagePeriodTo)) {
            this.showTable = false
            this.$refs.messagesForProject.addErrorMsg('利用期間の開始日は終了日よりも前の日付を指定してください。')
            return
        }
        this.$refs.modalBlockMessage.init()

        this.$_saveSearchCondition()

        const payload = {
          company_name: this.companyName,
          project_address: this.projectAddress,
          usage_period_from: this.usagePeriodFrom,
          usage_period_to: this.usagePeriodTo
        }

        this.$store.dispatch('findUsageInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
						if(res.message) return this.$refs.messagesForProject.addErrorMsg(res.message)

            this.usageStatusList.length = 0
            const usageStatusList = res.usageStatusList
            if(usageStatusList.length === 0) {
              this.showTable = false
              this.$refs.messagesForProject.addErrorMsg('条件に一致する物件情報データがありません。')
            } else {
              this.showTable = true
              for(let usageStatus of usageStatusList) {
                this.usageStatusList.push(
                  {
                    updateDate: cmnChangeFormatDate(new Date (Date.parse(usageStatus.project.projectUpdateDate)), 'date', 'YYYY/MM/DD'),
                    resignedFlag: codeToText(resignedFlagList, usageStatus.company.resignedFlag),
                    companyName: usageStatus.company.companyName,
                    email: usageStatus.userEmail,
                    projectNo: usageStatus.project.projectNo,
                    projectId: usageStatus.project.projectId,
                    projectName: usageStatus.project.projectName,
                    address: usageStatus.project.address
                  }
                )
              }
            }
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          })
      },

      /* 検索条件の保存 */
      $_saveSearchCondition() {
        this.searchConditionSaved.companyName = this.companyName
        this.searchConditionSaved.projectAddress = this.projectAddress
        this.searchConditionSaved.usagePeriodFrom = this.usagePeriodFrom
        this.searchConditionSaved.usagePeriodTo = this.usagePeriodTo
      },

      /* 一覧表示のリセット */
      $_tableReset(){
        this.usageStatusList.length = 0
        this.showTable = false
      },

      /* 出力ボタン（物件情報） */
      $_outputProject(projectNo) {
        this.$refs.modalBlockMessage.init()
        this.$_clearAllMsg()
        const payload = {
          project_no: projectNo,
        }
        this.$store.dispatch('outputProject', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messagesForUsageTime.addErrorMsg(res.message)
            if(!res.data.size) return this.$refs.messagesForUsageTime.addErrorMsg('ファイルの取得に失敗しました。')
            this.$_downloadFile(res)
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 

      },

      /* 一覧出力ボタン */
      $_outputProjectList() {
        this.$refs.modalBlockMessage.init()
        this.$_clearAllMsg()
        const payload = {
          company_name: this.searchConditionSaved.companyName,
          project_address: this.searchConditionSaved.projectAddress,
          usage_period_from: this.searchConditionSaved.usagePeriodFrom,
          usage_period_to: this.searchConditionSaved.usagePeriodTo
        }
        this.$store.dispatch('outputProjectList', payload)
          .then((res) => {
						this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messagesForProject.addErrorMsg(res.message)
            if(!res.data.size) return this.$refs.messagesForProject.addErrorMsg('ファイルの取得に失敗しました。')
            this.$_downloadFile(res)
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
      },

      /* 出力ボタン（利用時間） */
      $_outputUsageTime() {
				this.$refs.modalBlockMessage.init()
        this.$_clearAllMsg()
        const payload = {
          company_name: this.companyName,
        }
        this.$store.dispatch('outputUsageTime', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messagesForUsageTime.addErrorMsg(res.message)
            if(!res.data.size) return this.$refs.messagesForUsageTime.addErrorMsg('条件に一致するユーザ別利用時間データがありません。')
            this.$_downloadFile(res)
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
      },

      /* 取得したファイルのダウンロード */
      $_downloadFile(res) {
        const file = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/octet-stream' })
        const disposition = res.headers['content-disposition']
        const fileName = this.$_getFileName(disposition)
        const elem = document.createElement('a')
        elem.href = file
        elem.setAttribute('download', fileName)
        document.body.appendChild(elem)
        elem.click()
        elem.remove()
      },

      /* ファイル名の取得 */
      $_getFileName(disposition) {
        const regExpFileName = REGEXP.FILENAME
        const matches = regExpFileName.exec(disposition); // 正規表現で"filename*=UTF8''（ファイル名）.csv"を抜き出す
        if (matches != null && matches[0]) {
          const encodedFileName = matches[0].split('\'\'')[1];
          return decodeURI(encodedFileName)
        } else {
          return 'file.csv'
        }
      },

      $_clearAllMsg() {
        this.$refs.messagesForProject.clearAllMsg()
        this.$refs.messagesForUsageTime.clearAllMsg()
      }
    }
  }
</script>

<style lang="scss" scoped>
.my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 1320px;
}
.my-bg-color {
  background: rgba(128, 128, 128, 0.3);
}
  .table>:not(:first-child) {
  border-top: 1;
  border-top-color: gainsboro;
  }

  .table {
    white-space: normal;
    word-break: break-all;
  }

  th:nth-child(1),
  #td1 {
  width: 8%;
  }

  th:nth-child(2),
  #td2 {
  width: 8%;
  }

  th:nth-child(3),
  #td3 {
  width: 12%;
  }

  th:nth-child(4),
  #td4 {
  width: 16%;
  }

  th:nth-child(5),
  #td5 {
  width: 16%;
  }
  
  th:nth-child(6),
  #td6 {
  width: 20%;
  }

  th:nth-child(7),
  #td7 {
  width: 16%;
  }

  th:nth-child(8),
  #td8 {
  width: 4%;
  }

  button {
  white-space: nowrap;
  }
</style>