<template>
	<div id="customer-management-main" class="my-overflow-y mx-sm-3 mx-5 mt-4">
		<h4 class="mb-4">顧客管理</h4>
    <!-- 条件指定エリア -->
		<div class="container">
			<div class="row align-items-center pt-1 border-top">
				<div class="col-sm-3 py-1 my-bg-color fw-bold">会社名</div>
				<div class="col-sm-9">
          <input class="p-1 m-0 form-control form-control-sm" type="text" v-model="companyName" maxlength="64" />
        </div>
			</div>

			<div class="row align-items-center mt-1 pt-1 border-top">
				<div class="col-sm-3 py-1 my-bg-color fw-bold">決済手段</div>
				<div class="col-sm-5">
					<select class="form-select form-select-sm" v-model="paymentMethod">
						<option v-for="item in paymentMethods" :key="item.code" :selectedPaymentMethod="item.code" :value="item.code">
              {{ item.text }}
						</option>
					</select>
				</div>
			</div>

			<div class="row align-items-center mt-1 pt-1 border-top">
				<div class="col-sm-3 py-1 my-bg-color fw-bold">ステータス</div>
				<div class="col-sm-5">
					<select class="form-select form-select-sm" v-model="status">
						<option v-for="item in statuses" :key="item.code" :value="item.code">{{ item.text }}
						</option>
					</select>
				</div>
			</div>

			<div class="row align-items-center my-1 py-1 border-top border-bottom">
				<div class="col-sm-3 py-1 my-bg-color fw-bold">退会済を含む</div>
				<div class="col-sm-5">
					<input class="form-check-input" type="checkbox" v-model="containsResignedCustomer" >
				</div>
			</div>
      <messages ref="messages"></messages>

      <div class="row align-items-center">
				<div class="my-2 col-sm-12 text-center"><button class="btn btn-primary"
            @click="$_viewBtn()">表示</button></div>
			</div>

      <!-- 一覧表示エリア -->
			<div class="col-12" v-if="showList">
				<table class="table table-bordered table-sm small my-5 table-hover">
					<thead class="table-light align-middle">
						<tr>
							<th v-for="field in customerListHeader" :key="field.key">{{ field.label }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(customer, index) in customerList" :key="index" :class="{highlighted: $_isApprovable(customer)}" >
							<td id="td1" class="text-center px-0">
								<input class="form-check-input" type="checkbox" v-model="customer.isChecked" :disabled="!$_isApprovable(customer)">
							</td>
							<td id="td2">{{ customer.companyName }}</td>
							<td id="td3">{{ customer.supervisorName }}</td>
							<td id="td4">{{ customer.numberOfLicenses }}</td>
							<td id="td5">{{ customer.paymentMethod }}</td>
							<td id="td6"><button class="btn btn-link btn-sm" type="link"
									@click="$_moveToUserList(customer.companyId)">{{ customer.userCount }}</button></td>
							<td id="td7">{{ customer.status }}</td>
							<td id="td8">{{ customer.resignedFlag }}</td>
							<td id="td9">{{ customer.registDate }}</td>
							<td id="td10"><button class="btn btn-primary btn-sm" type="button"
									@click="$_moveToRegistrationInfoCheck(customer.companyId)">詳細</button></td>
							<td id="td11"><button class="btn btn-primary btn-sm" type="button"
									@click="$_moveToBillingInfo(customer.companyId)">請求情報</button></td>
						</tr>
					</tbody>
				</table>
				<div class="text-end p-2">
					<button class="btn btn-primary" 
            @click="$_approveBtn()">承認</button>
				</div>
			</div>
		</div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="customer-management-main" />
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="customer-management-main" @return-confirm-result="returnConfirmResult" />
	</div>
</template>

<script>
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import ModalConfirm from '@/views/common/ModalConfirm'
  import Messages from '@/views/common/Messages'
  import { paymentMethodList, statusList, resignedFlagList, codeToText } from '@/assets/js/code.js'
  import { cmnChangeFormatDate } from '@/assets/js/common.js'
  import { RESULT_CODE } from '@/assets/js/const'


	export default {
		name: 'MainSection',
		components: {
			ModalBlockMessage,
			ModalConfirm,
			Messages,
		},
		data() {
			return {
        customerList: [],
        customerListHeader: [
					{ key: 'approval', label: '承認' },
					{ key: 'companyName', label: '会社名' },
					{ key: 'supervisorName', label: '担当者名' },
					{ key: 'numberOfLicenses', label: 'ライセンス数' },
					{ key: 'paymentMethod', label: '決済手段' },
					{ key: 'userCount', label: 'ユーザ数' },
					{ key: 'status', label: 'ステータス' },
					{ key: 'resignedFlag', label: '会員状況' },
					{ key: 'registDate', label: '登録日' },
					{ key: 'detailBtn', label: null },
					{ key: 'billingInfoBtn', label: null }
				],
				companyName: null,
				paymentMethod: null,
        status: null,
				containsResignedCustomer: false,
				statusList: [],
        confirmMessages: [],
				showList: null,
				isBack: this.$route.params.isBack
			}
		},

		computed: {
			paymentMethods: function() {
				const paymentMethods = paymentMethodList.slice()
				paymentMethods.unshift({ code: "", text: ""})
				return paymentMethods
			},

			statuses: function() {
				const statuses = statusList.slice()
				statuses.unshift({ code: "", text: ""})
				return statuses
			}

		},
		mounted() {
			if(this.isBack) {
				this.companyName = this.$store.state.searchParams.companyName
				this.paymentMethod = this.$store.state.searchParams.paymentMethod
				this.status = this.$store.state.searchParams.status
				this.containsResignedCustomer = this.$store.state.searchParams.containsResignedCustomer
				this.$_viewList()
			}
		},
		methods: {
			/* 承認アクションができるステータスのときtrue */
			$_isApprovable(customer) {
				return (customer.status == '未承認' || customer.status == '変更承認待ち' && customer.resignedFlag != '退会済')
			},

			$_viewBtn() {
				this.$refs.messages.clearAllMsg()
				this.$_viewList()
			},

      /* 検索と一覧表示 */
			$_viewList() {
        this.$refs.modalBlockMessage.init()
        this.customerList.length = 0
				this.showList = false
        let payload = {
          company_name: this.companyName,
          payment_method: this.paymentMethod,
          approved_flag: this.status,
					contains_resigned_customer: this.containsResignedCustomer
        }

				this.$_storeSearchParams()
        this.$store.dispatch('findCustomers', payload)
          .then((res) => {
						this.$refs.modalBlockMessage.close()
						if(res.message) return this.$refs.messages.addErrorMsg(res.message)
						const customerDataList = res.customerDataList
            if(customerDataList.length === 0) {
              this.$refs.messages.addErrorMsg('条件に一致する顧客データがありません。')
            } else {
              for (let customerData of customerDataList) {
                this.customerList.push(
                  { 
                    isChecked: false,
                    companyId: customerData.company.companyId,
                    companyName: customerData.company.companyName, 
                    supervisorName: customerData.supervisorName, 
                    numberOfLicenses: customerData.company.numberOfLicenses, 
                    paymentMethod: codeToText(paymentMethodList, customerData.charge.paymentMethod),
                    userCount: customerData.userCount,
                    status: codeToText(statusList, customerData.company.approvedFlag),
										resignedFlag: codeToText(resignedFlagList, customerData.company.resignedFlag),
                    registDate: cmnChangeFormatDate(new Date (Date.parse(customerData.company.registDate)), 'date', 'YYYY/MM/DD')
                  })
                
              }
              this.showList = true
            }
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
			},

			/* 検索条件の保存 */
			$_storeSearchParams() {
				const payload = {
					companyName: this.companyName,
					paymentMethod: this.paymentMethod,
					status: this.status,
					containsResignedCustomer: this.containsResignedCustomer
				}
				this.$store.commit('setSearchParams', payload)
			},

			/* ユーザ一覧に遷移 */
			$_moveToUserList(companyId) {
				this.$router.push({ path: '/userList', name: 'UserList', params: { companyId: companyId } })
			},

			/* 登録情報確認に遷移 */
			$_moveToRegistrationInfoCheck(companyId) {
				this.$router.push({ path: '/registrationInfoCheck', name: 'RegistrationInfoCheck', params: { companyId: companyId } })
			},

      /* 請求情報に遷移 */
			$_moveToBillingInfo(companyId) {
				this.$router.push({ path: '/billingInfo', name: 'BillingInfo', params: { companyId: companyId } })
			},

			/* 一括承認ボタン */
			$_approveBtn() {
				this.$refs.messages.clearAllMsg()
				const checkedCustomerList = this.customerList.filter((item) => { return item.isChecked}) 
				if(checkedCustomerList.length === 0) {
							return this.$refs.messages.addErrorMsg('承認する顧客が選択されていません。')
						}
        this.confirmMessages = []
        this.confirmMessages.push('選択した顧客を一括承認します。', 'よろしいですか？')
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()

			},

      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.OK) this.$_approveCustomerList()
      },
      
      /* 一括承認 */
      $_approveCustomerList() {
        this.$refs.modalBlockMessage.init()

        // チェックされた行の会社情報のリストを取得
        const checkedCustomerList = this.customerList.filter((item) => {
          return item.isChecked
        })

        const payload = []

        for(let item of checkedCustomerList) {
          payload.push(item.companyId)
        }
        this.$store.dispatch('bulkApproval', payload)
					.then((res) => {
						this.$refs.modalBlockMessage.close()
						window.scrollTo(0, 0)
						if(res.message) return this.$refs.messages.addErrorMsg(res.message)
						this.$_viewList()
						this.$emit('reloadMenuSection')
						this.$refs.messages.addSuccessMsg('選択した顧客を承認しました。')
					})
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 
      },
		}
	}
</script>

<style lang="scss" scoped>
.my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 1320px;
}
.my-bg-color {
  background: rgba(128, 128, 128, 0.3);
}
	.table>:not(:first-child) {
		border-top: 1;
		border-top-color: gainsboro;
	}

	#td1 {
		width: 5%;
	}
	#td2 {
		width: 25%;
	}
	#td3 {
		width: 10%;
	}
	#td4 {
		width: 10%;
	}
	#td5 {
		width: 15%;
	}
	#td6 {
		width: 8%;
	}
	#td7 {
		width: 9%;
	}
	#td8 {
		width: 8%;
	}
	#td9 {
		width: 10%;
	}
	#td10 {
		width: 50px;
	}
	#td11 {
		width: 80px;
	}
	.highlighted {
		background: #ffe6d5;
	}


	button {
		white-space: nowrap;
	}

</style>