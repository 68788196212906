<template>
  <router-view v-slot="{ Component }">
    <transition>
      <component :is="Component" />
    </transition>
    <modal-system-error />
  </router-view>
</template>

<script>
import ModalSystemError from '@/views/common/ModalSystemError'
export default {
  name: 'App',
  components: {
    ModalSystemError
  },
  mounted() {
    const ua = navigator.userAgent
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 || ua.indexOf('Safari') > 0 && typeof document.ontouchstart !== 'undefined') {
      // alert("ipad確認用")
      window.addEventListener('load', this.onResize)
    }
    else {
      window.addEventListener('load', this.onResize)
      window.addEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      const vh = window.innerHeight
      document.getElementById('app').style.height=vh+'px'
    }
  }
}
</script>
<!-- <style lang="scss">

</style> -->
