<template>
  <div id="user-list-main" class="my-overflow-y mx-5 mt-4 py-2">
    <h4 class="mb-4">ユーザ一覧</h4>
    <messages ref="messages"></messages>
    <div class="container pe-sm-0 pe-xl-3">
      <div class="row justify-content-xl-between align-items-xl-end">
        <div class="row row-cols-sm-2 col-xl-9 pe-sm-0 pe-xl-3">
          <span class="col-sm-3 border py-1 my-bg-color fw-bold">会社名</span>
          <span class="col-sm-9 border py-1">{{ customerInfo.companyName }}</span>
          <span class="col-sm-3 border border-top-0 py-1 my-bg-color fw-bold">ライセンス</span>
          <span class="col-sm-9 border border-top-0 py-1">割当済み件数 {{ customerInfo.usedLicenseNum }} 件 / 所持件数 {{ customerInfo.licenseNum }} 件</span>
          <span class="col-sm-3 border border-top-0 py-1 my-bg-color fw-bold">ユーザ数</span>
          <span class="col-sm-9 border border-top-0 py-1">{{ userCount }} 人</span>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h5>ライセンスありユーザ</h5>
      <table class="table table-bordered table-sm small table-hover mt-3 align-middle text-break">
        <thead class="table-light align-middle">
          <tr>
            <th v-for="header in userListHeader" :key="header.key">{{ header.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in withLicenseList" :key="index">
            <td id="td1">{{ user.userEmail }}</td>
            <td id="td2">{{ user.userName }}</td>
            <td id="td3">{{ $_authorityCodeToText(user.authority) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <h5>ライセンスなしユーザ</h5>
      <table class="table table-bordered table-sm small table-hover mt-3 align-middle text-break">
        <thead class="table-light align-middle">
          <tr>
            <th v-for="header in userListHeader" :key="header.key">{{ header.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in withoutLicenseList" :key="index">
            <td id="td1">{{ user.userEmail }}</td>
            <td id="td2">{{ user.userName }}</td>
            <td id="td3">{{ $_authorityCodeToText(user.authority) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-end mt-5 px-2">
      <button class="btn btn-primary" @click="$_back">戻る</button>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="user-list-main" />
  </div>
</template>

<script>
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import Messages from '@/views/common/Messages'
  import { authorityList, codeToText } from '@/assets/js/code.js'
  import { FLAG } from '@/assets/js/const'

  export default {

    name: 'MainSection',

		components: {
      ModalBlockMessage,
			Messages,
		},

    data() {
      return {
        customerInfo: {
          companyId: this.$route.params.companyId,
          comanyName: null,
          usedLicenseNum: null,
          licenseNum: null,
        },
        userCount: null,
        userList: [],
        withLicenseList: [],
        withoutLicenseList: [],
        userListHeader: [
          { key: 'email', label: 'メールアドレス' },
          { key: 'name', label: '名前' },
          { key: 'authority', label: '権限' }
        ],
        backing: false,
      }
    },

    mounted() {
      this.$_viewUserList()
    },

    methods: {
      /* ユーザ一覧の取得 */
      $_viewUserList() {
        this.$refs.modalBlockMessage.init()
        let payload = {
          company_id: this.$route.params.companyId
        }
        this.$store.dispatch('findUserInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            
            let cstInfo = this.customerInfo
            cstInfo.companyName = res.company.companyName
            cstInfo.usedLicenseNum = 0  // devideUserListで設定
            cstInfo.licenseNum = res.company.numberOfLicenses
            this.userCount = res.users.length
            this.$_devideUserList(res.users)
          })
					.catch((e) => {
						this.$refs.modalBlockMessage.close()
						console.log(e)
					}) 

      },

      /* ライセンスの有無の振り分け */
      $_devideUserList(userList) {
        this.withoutLicenseList.length = 0
        this.withLicenseList.length = 0

        this.withoutLicenseList = userList.filter((item) => {
          return item.license === FLAG.OFF
        })
        this.withLicenseList = userList.filter((item) => {
          return item.license === FLAG.ON
        })
        this.customerInfo.usedLicenseNum = this.withLicenseList.length
      },

      /* 権限の変換 */
      $_authorityCodeToText(authority) {
        return codeToText(authorityList, authority)
      },
      
      /* 戻る */
      $_back() {
        this.$emit('backing')
        this.$router.back()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 1320px;
  }
  .my-bg-color {
    background: rgba(128, 128, 128, 0.3);
  }

  .table>:not(:first-child) {
    border-top: 1;
    border-top-color: gainsboro;
  }

  
  th:nth-child(1),
  #td1 {
    width: 40%;
  }

  th:nth-child(2),
  #td2 {
    width: 20%;
  }

  th:nth-child(3),
  #td3 {
    width: 10%;
  }

  button {
    white-space: nowrap;
  }
</style>